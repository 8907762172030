<script setup lang="ts">
const pers = ref(false)
const open = ref(true)

const zarazConsent = useCookie('zaraz-consent', { maxAge: 60 * 60 * 24 * 365 * 10 })

const consents = [
  {
    id: 'tcf-purposes-1',
    label: 'Przechowywanie informacji na urządzeniu lub dostęp do nich',
    content: 'Pliki cookie, identyfikatory urządzeń lub podobne identyfikatory online (np. identyfikatory oparte na logowaniu, identyfikatory przypisywane losowo, identyfikatory sieciowe) w połączeniu z innymi informacjami (takimi jak rodzaj przeglądarki i informacje w niej zawarte, język, rozmiar ekranu, obsługiwane technologie itp.) mogą być przechowywane lub odczytywane na Twoim urządzeniu celem rozpoznania urządzenia za każdym razem, gdy następuje połączenie z aplikacją lub witryną internetową – w celach tutaj przedstawionych.',
  },
  {
    id: 'tcf-purposes-2',
    label: 'Wykorzystywanie ograniczonych danych do wyboru reklam',
    content: 'Reklamy prezentowane Tobie w serwisie mogą opierać się na ograniczonych danych takich jak witryna internetowa lub aplikacja, z których korzystasz, Twoja nieprecyzyjna lokalizacja, rodzaj urządzenia albo treści przeglądane przez Ciebie (na przykład w celu ograniczenia liczby wyświetleń danej reklamy).',
  },
  {
    id: 'tcf-purposes-3',
    label: 'Tworzenie profili w celu spersonalizowanych reklam',
    content: 'Informacje na temat Twojej aktywności w serwisie (np. przesyłane formularze, przeglądane treści) mogą być przechowywane i łączone z innymi informacjami dotyczącymi Ciebie (na przykład informacjami o Twojej poprzedniej aktywności w serwisie, innych witrynach internetowych lub aplikacjach) lub podobnych osób. Informacje te wykorzystuje się następnie do tworzenia lub ulepszania profilu odnoszącego się do Ciebie (który to profil może na przykład obejmować Twoje zainteresowania i cechy osobiste). Twój profil może być wykorzystywany (również później) do pokazywania reklam, które wydają się bardziej odpowiednie, biorąc pod uwagę Twoje możliwe zainteresowania.',
  },
  {
    id: 'tcf-purposes-4',
    label: 'Wykorzystanie profili do wyboru spersonalizowanych reklam',
    content: 'Reklamy, ktore są Ci prezentowane w tym serwisie mogą być oparta na Twoich profilach reklamowych, które mogą odzwierciedlać Twoją aktywność w serwisie, innych witrynach internetowych lub aplikacjach (np. przesyłane formularze, przeglądane treści), możliwe zainteresowania i cechy osobiste.',
  },
  {
    id: 'tcf-purposes-7',
    label: 'Pomiar efektywności reklam',
    content: 'Informacje dotyczące tego, które reklamy są Ci pokazywane, jak również tego, jak na nie reagujesz, mogą pomóc określić, na ile dobrze zadziałała dana reklama na Ciebie lub inne osoby i czy cele reklamy zostały osiągnięte. Na przykład czy w ogóle widziałeś reklamę, czy ją na nią kliknąłeś, czy skłoniła to Ciebie do zakupu produktu lub odwiedzenia witryny internetowej itp. Jest to bardzo pomocne w zrozumieniu znaczenia kampanii reklamowych.',
  },
  {
    id: 'tcf-purposes-9',
    label: 'Rozumienie odbiorców dzięki statystyce lub kombinacji danych z różnych źródeł',
    content: 'Można generować raporty na podstawie kombinacji zestawów danych (takich jak profile użytkowników, statystyki, badania rynku, dane analityczne) dotyczących reakcji użytkowników na treści reklamowe lub niereklamowe. Chodzi o identyfikację wspólnych cech (na przykład określenie, którzy odbiorcy docelowi są bardziej otwarci na kampanię reklamową lub treści danego typu).',
  },
  {
    id: 'tcf-purposes-10',
    label: 'Rozwój i ulepszanie usług',
    content: 'Informacje na temat Twojej aktywności w serwisie, np. odnoszące się do Twoich reakcji na reklamy lub treści, mogą być bardzo pomocne w ulepszaniu produktów i usług oraz tworzeniu nowych produktów i usług w oparciu o Twoje interakcje, typ odbiorców itp. Ten konkretny cel nie obejmuje tworzenia ani ulepszania profili czy identyfikatorów użytkowników.',
  },
]

const acceptedAll = ref(false)
const rejectedAll = ref(false)

const accepts = reactive(consents.reduce((acc, consent) => {
  acc[consent.id] = false
  return acc
}, {}))

const opened = reactive(consents.reduce((acc, consent) => {
  acc[consent.id] = false
  return acc
}, {}))

function acceptAll() {
  rejectedAll.value = false
  acceptedAll.value = true

  Object.keys(accepts).forEach((key) => {
    accepts[key] = true
  })

  Object.keys(opened).forEach((key) => {
    opened[key] = true
  })
}

function rejectAll() {
  acceptedAll.value = false
  rejectedAll.value = true

  Object.keys(accepts).forEach((key) => {
    accepts[key] = false
  })

  Object.keys(opened).forEach((key) => {
    opened[key] = false
  })
}

function saveAndClose() {
  acceptAll()

  zarazConsent.value = accepts
}
</script>

<template>
  <UModal
    v-model="open"
    :prevent-close="true"
  >
    <div class="flex flex-col">
      <div class="bg-gray-900 flex w-full justify-center p-4 rounded-t-md">
        <div
          v-if="pers"
          class="absolute left-4 top-4"
        >
          <UButton
            color="gray"
            variant="soft"
            icon="i-bitcoin-icons-arrow-left-filled"
            @click="pers = false"
          />
        </div>
        <NuxtImg
          src="/logo-white.webp"
          alt="bitcoin.pl logo"
          width="128"
          height="32"
          format="avif"
          preload
        />
      </div>
      <div class="flex flex-col divide-y divide-gray-200/60 max-h-[500px] overflow-auto">
        <template v-if="!pers">
          <div class="p-4 text-xs md:text-sm text-gray-800 bg-gray-50">
            <p>Używamy własnych ciasteczek i ciasteczek stron trzecich, abyśmy mogli poprawnie wyświetlać tę witrynę i lepiej zrozumieć, w jaki sposób jest ona używana, w celu ulepszenia oferowanych przez nas usług.</p>
            <p class="mt-4 font-medium">
              W tym celu prosimy Cię o zgodę na:
            </p>
          </div>
          <div class="flex items-center gap-4 p-4">
            <div class="bg-gray-200 rounded-full size-8 flex items-center justify-center shadow shrink-0">
              <UIcon
                name="i-bitcoin-icons-shared-wallet-outline"
                class="text-orange-500"
              />
            </div>
            <p class="text-xs md:text-sm">
              Przechowywanie informacji na urządzeniu lub dostęp do nich.
            </p>
          </div>
          <div class="flex items-center gap-4 p-4 ">
            <div class="bg-gray-200 rounded-full size-8 flex items-center justify-center shadow shrink-0">
              <UIcon
                name="i-bitcoin-icons-node-2-connections-filled"
                class="text-orange-500"
              />
            </div>
            <p class="text-xs md:text-sm">
              Tworzenie i wykorzystywanie profili w celu dostarczania spersonalizowanych reklam oraz pomiaru ich efektywności.
            </p>
          </div>
          <div class="flex items-center gap-4 p-4 ">
            <div class="bg-gray-200 rounded-full size-8 flex items-center justify-center shadow shrink-0">
              <UIcon
                name="i-bitcoin-icons-graph-filled"
                class="text-orange-500"
              />
            </div>
            <p class="text-xs md:text-sm">
              Zbieranie danych analitycznych w celu rozwoju i ulepszania usług.
            </p>
          </div>
        </template>
        <template v-else>
          <div
            v-for="consent in consents"
            :key="consent.id"
            class="flex flex-col p-4 gap-4"
          >
            <div class="flex items-center gap-4">
              <div
                class="cursor-pointer rounded-full size-4 flex items-center justify-center shrink-0"
                @click="opened[consent.id] = !opened[consent.id]"
              >
                <UIcon
                  name="i-bitcoin-icons:arrow-down-filled"
                  class="text-orange-500"
                />
              </div>
              <p
                class="cursor-pointer text-sm grow"
                @click="opened[consent.id] = !opened[consent.id]"
              >
                {{ consent.label }}
              </p>
              <UToggle v-model="accepts[consent.id]" />
            </div>
            <div
              v-if="opened[consent.id]"
              class="text-xs"
            >
              {{ consent.content }}
            </div>
          </div>
        </template>
      </div>
      <div class="p-4 flex justify-between flex-wrap-reverse gap-4 border-y border-gray-200/60">
        <template v-if="!pers">
          <UButton
            size="lg"
            color="orange"
            variant="soft"
            class="grow"
            @click="pers = true"
          >
            Chcę spersonalizować wybór
          </UButton>
          <UButton
            size="lg"
            class="grow md:block"
            @click="saveAndClose"
          >
            Akceptuję wszystkie
          </UButton>
        </template>

        <template v-else>
          <UButton
            size="lg"
            color="orange"
            variant="soft"
            class="grow"
            @click="acceptedAll ? rejectAll() : acceptAll()"
          >
            {{ acceptedAll ? 'Odrzucam wszystkie' : 'Akceptuję wszystkie' }}
          </UButton>
          <UButton
            size="lg"
            @click="saveAndClose"
          >
            Potwierdzam wybór
          </UButton>
        </template>
      </div>
      <div class="p-4 text-xs text-gray-700 bg-red-50 rounded-b-md">
        W każdej chwili możesz zmienić swoje decyzje usuwając ciasteczka w witrynie i ponownie akceptując zgody z innymi opcjami.
      </div>
    </div>
  </UModal>
</template>
